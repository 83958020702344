import { useCallback, useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import * as Types from '@/api/@types';
import Image from '@/modules/components/image';
import { read } from '@/modules/layouts/shared/header/navi-item/information/sevices';

const useStyles = makeStyles(() =>
  createStyles({
    noticeList: {
      padding: '0 20px',
      '& .notice-list-items': {
        '& td.title': {
          '& .name': {
            display: 'block',
            color: '#c00',
            fontWeight: '700',
            fontSize: '12px',
            textAlign: 'left',
          },
          '& .point_value': {
            display: 'block',
            color: '#c00',
            fontWeight: 700,
            lineHeight: 2,
            fontSize: '12px',
            textAlign: 'left',
          },
          '& .description': {
            display: 'block',
            fontSize: '12px',
            textAlign: 'left',
          },
        },
        '& td.image': {
          width: '56px',
          '& img.jacket': {
            objectFit: 'contain',
          },
        },
        '& td:not(.image)': {
          padding: '3px 0 3px 10px',
        },
        '& .notice-list-item': {
          border: 'solid 1px #f0f0f0',
          background: '#fff',
          color: '#333',
          margin: '10px 0',
          padding: '10px',
          '&.link-on:hover': {
            background: '#e8e8e8',
          },
          '&.link-off': {
            '& a': {
              pointerEvents: 'none',
            },
          },
        },
      },

      '& .empty': {
        color: '#000000',
        textAlign: 'left',
        fontSize: '13px',
        padding: '20px 0',

        '& p': {
          marginTop: '8px',
          color: '#666666',
          fontSize: '95%',
          lineHeight: 1.6,
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_IM_URL } = process.env;

type ItemProps = {
  information: Types.HeaderNotice;
  onClickNotice: (information: Types.HeaderNotice) => void;
};
const InformationItem: React.FC<ItemProps> = ({ information, onClickNotice }) => {
  let imgUrl: string;
  // content
  if (information.icon_type === 2 && information.content_id) {
    const id = `000${information.content_id}`.substr(-4);
    imgUrl = `${NEXT_PUBLIC_IM_URL}/content/${id.charAt(1)}${id.charAt(0)}/${id.charAt(3)}${id.charAt(2)}/${
      information.content_id
    }/jacket_h_l.jpg`;
    // coupon
  } else if (information.type === 1) {
    imgUrl = `${NEXT_PUBLIC_IM_URL}/img/pc/parts/coupon.png`;
    // point
  } else if (information.type === 2) {
    imgUrl = `${NEXT_PUBLIC_IM_URL}/img/pc/parts/point.png`;
  }

  const onClickItem = useCallback(() => {
    if (!information.read_detail) {
      read(information);
    }
    onClickNotice(information);
  }, [information, onClickNotice]);

  return useMemo(
    () => (
      <div className={`notice-list-item ${information.url ? 'link-on' : 'link-off'}`}>
        <a href={information.url ?? undefined} target="_blank" rel="noopener noreferrer" onClick={onClickItem}>
          <table>
            <tbody>
              <tr>
                <td rowSpan={2} className="image">
                  <Image src={imgUrl} fallbackImageType="jacket" width={56} height={80} className="jacket" alt="icon" />
                </td>
                <td className="title">
                  <div className="name">{information.title}</div>
                  {information.type === 2 && information.point_value && (
                    <div className="point_value">{information.point_value}</div>
                  )}
                  <div className="description">{information.description}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
      </div>
    ),
    [information, onClickItem, imgUrl],
  );
};

type ListProps = {
  informations: Array<Types.HeaderNotice>;
  onClickNotice: (information: Types.HeaderNotice) => void;
};
const InformationList: React.FC<ListProps> = ({ informations, onClickNotice }) => {
  const classes = useStyles();

  return useMemo(
    () => (
      <div className={classes.noticeList}>
        <div className="notice-list-items">
          {informations?.length > 0 ? (
            informations?.map((v) => <InformationItem key={v.id} information={v} onClickNotice={onClickNotice} />)
          ) : (
            <div className="empty">
              現在新しい情報はありません。
              <p>Rakuten TVをもっと楽しんで頂くために、便利でお得な情報をタイムリーにお知らせします！</p>
            </div>
          )}
        </div>
      </div>
    ),
    [classes, informations, onClickNotice],
  );
};

export default InformationList;
