import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import UniversalLinksForAndroid from '@/modules/layouts/shared/footer/universal-link/index.android';
import UniversalLinksForiOS from '@/modules/layouts/shared/footer/universal-link/index.ios';
import SnsWidgets from '@/modules/layouts/shared/footer/sns-widgets';
import MNOBanner from '@/modules/layouts/shared/footer/mno-banner';
import Image from '@/modules/components/image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: '#1E2427',
      '& a:hover': {
        textDecoration: 'underline',
      },
    },
    section1: {
      padding: '20px 8px',
      [theme.breakpoints.up('md')]: {
        padding: '40px 20px 30px',
      },
    },
    flex: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cell: {
      padding: '0 8px',
    },
    title: {
      fontSize: '14px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
      fontWeight: 'bold',
      color: '#999FA4',
      borderBottom: '1px solid #41474C',
      padding: '0 0 12px 0',
      maragin: '0 0 12px 0',
    },
    usage: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        paddingBottom: '36px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '28%',
        paddingBottom: '0',
      },
      fontSize: '12px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      color: '#5A6166',
    },
    usageListItem: {
      marginTop: '1em',
      '& a': {
        color: '#C5C8CE',
      },
    },
    guide: {
      width: '50%',
      marginTop: '32px',
      [theme.breakpoints.up('md')]: {
        width: '25%',
        marginTop: '0',
      },
      [theme.breakpoints.up('lg')]: {
        width: '20%',
      },
    },
    guideList: {
      marginTop: '4px',
    },
    guideItem: {
      marginTop: '8px',
      '& a': {
        display: 'block',
        padding: '4px',
        fontSize: '12px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
        },
        color: '#999FA4',
      },
    },
    app: {
      width: '100%',
      margin: '32px auto 0',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '360px',
      },
      [theme.breakpoints.up('md')]: {
        width: '50%',
        maxWidth: 'initial',
        margin: '0',
      },
      [theme.breakpoints.up('lg')]: {
        width: '32%',
      },
    },
    appWrap: {
      display: 'grid',
      'grid-template-columns': 'repeat(2, 1fr)',
    },
    sns: {
      textAlign: 'center',
      marginTop: '40px',
    },
    snsTitle: {
      marginBottom: '12px',
      fontSize: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
      },
      color: '#999FA4',
    },
    greenlogo: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      '& a': {
        width: '150px',
        height: '40px',
        [theme.breakpoints.up('md')]: {
          width: '220px',
          height: '56px',
        },
      },
    },
    copyright: {
      fontSize: '10px',
      color: '#5A6166',
      marginTop: '32px',
      textAlign: 'center',
    },
    section2: {
      padding: '16px 0 88px',
      background: '#0A0A0A',
    },
    section2Inner: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 16px',
      [theme.breakpoints.up('md')]: {
        padding: '0',
      },
    },
    linkList: {
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '12px',
      [theme.breakpoints.up('md')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
      },
    },
    linkItem: {
      margin: '0 .25em',
      padding: '8px 0',
      [theme.breakpoints.up('md')]: {
        padding: '0',
      },
      '& a': {
        padding: '4px',
        display: 'inlineBlock',
        whiteSpace: 'nowrap',
        color: '#9D9D9D',
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const { NEXT_PUBLIC_FAQ_NET } = process.env;
const { NEXT_PUBLIC_IM_URL } = process.env;

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <MNOBanner />
      <div className={classes.section1}>
        <div className={classes.flex}>
          <div className={`${classes.cell} ${classes.usage}`}>
            <p className={classes.title}>ご利用方法について</p>
            <ul>
              <li>
                <ul>
                  <li className={classes.usageListItem}>
                    Rakuten TVでは楽天IDの登録以外にRakuten TVへのサービス登録が必要です。
                  </li>
                  <li className={classes.usageListItem}>
                    楽天IDをお持ちの方
                    <br />
                    楽天IDの登録については
                    <a href="https://www.rakuten.co.jp/myrakuten/help/">こちら</a>
                  </li>
                  <li className={classes.usageListItem}>
                    楽天IDをお持ちでない方
                    <br />
                    Rakuten TVのサービス登録については
                    <a href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001868`}>こちら</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={`${classes.cell} ${classes.guide}`}>
            <p className={classes.title}>Rakuten TV について</p>
            <ul className={classes.guideList}>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/`}>初めての方へ</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/device/`}>購入方法・視聴方法</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}`}>ヘルプ</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/copyright/`}>コピーライト一覧</a>
              </li>
            </ul>
          </div>
          <div className={`${classes.cell} ${classes.guide}`}>
            <p className={classes.title}>よくある質問</p>
            <ul className={classes.guideList}>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}/category/rtv/beginner-rtv/fee/search`}>料金について</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001746`}>視聴期限について</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001750`}>画質について</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001649`}>ポイント・クーポンについて</a>
              </li>
              <li className={classes.guideItem}>
                <a href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001861`}>支払い方法について</a>
              </li>
            </ul>
          </div>
          <div className={`${classes.cell} ${classes.app}`}>
            <p className={classes.title}>Rakuten TVアプリ</p>
            <ul className={classes.appWrap}>
              <li>
                <UniversalLinksForiOS />
              </li>
              <li>
                <UniversalLinksForAndroid />
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.sns}>
          <p className={classes.snsTitle}>公式ソーシャルメディアのご紹介</p>
          <SnsWidgets />
        </div>
        <div className={classes.greenlogo}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://corp.rakuten.co.jp/event/climateaction/#slide_1?scid=wi_rtv_gogreen_footer"
          >
            <Image
              src={`${NEXT_PUBLIC_IM_URL}/img/greenlogo/pc_w220xh56.svg`}
              alt="本サービスは再生可能エネルギー100%"
              width={220}
              height={56}
            />
          </a>
        </div>
        <p className={classes.copyright}>&copy; Rakuten Group, Inc.</p>
      </div>

      <div className={classes.section2}>
        <div className={classes.section2Inner}>
          <ul className={classes.linkList}>
            <li className={classes.linkItem}>
              <a href="//www.rakuten.co.jp/sitemap/">サービス一覧</a>
            </li>
            <li className={classes.linkItem}>
              <a href="//www.rakuten.co.jp/sitemap/inquiry.html">お問い合わせ一覧</a>
            </li>
            <li className={classes.linkItem}>
              <a href="//corp.rakuten.co.jp/" rel="nofollow">
                企業情報
              </a>
            </li>
            <li className={classes.linkItem}>
              <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/rule/`} rel="nofollow">
                利用規約
              </a>
            </li>
            <li className={classes.linkItem}>
              <a href="//privacy.rakuten.co.jp/" rel="nofollow">
                個人情報保護方針
              </a>
            </li>
            <li className={classes.linkItem}>
              <a href="//corp.rakuten.co.jp/csr/">社会的責任[CSR]</a>
            </li>
            <li className={classes.linkItem}>
              <a href="//corp.rakuten.co.jp/careers/">採用情報</a>
            </li>
            <li className={classes.linkItem}>
              <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/transactions/`} rel="nofollow">
                特定商取引法に基づく表記
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
