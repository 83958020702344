import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import FavoriteOutline from '@/assets/icons/rex/FavouritesOutlined.svg';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const URL = `${NEXT_PUBLIC_RTV_WEB_URL ?? ''}/bookmark`;

type Props = {
  className?: string;
};
const Favorite: React.FC<Props> = ({ className }) => (
  <NaviItemBase href={URL} icon={<FavoriteOutline />} label="お気に入り" className={className} />
);

export default Favorite;
