/* eslint-disable import/prefer-default-export */

type SearchOptionType = {
  value: string;
  label: string;
};

export const SEARCH_OPTIONS: SearchOptionType[] = [
  { value: 'all', label: 'すべてのジャンル' },
  { value: 'foreign_film', label: '洋画' },
  { value: 'japanese_film', label: '邦画' },
  { value: 'overseas_drama', label: '海外ドラマ' },
  { value: 'taiwanese_drama', label: '中国・台湾・タイドラマ' },
  { value: 'domestic_drama', label: '国内ドラマ' },
  { value: 'korean_drama', label: '韓国ドラマ' },
  { value: 'animation', label: 'アニメ' },
  { value: 'kids', label: 'キッズ' },
  { value: 'variety', label: 'バラエティ' },
  { value: 'idol_gravure', label: 'アイドル・グラビア' },
  { value: 'stage', label: '宝塚・舞台' },
  { value: 'music', label: 'ミュージック' },
  { value: 'documentary', label: 'ドキュメンタリー' },
  { value: 'sports', label: 'スポーツ' },
];
