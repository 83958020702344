/* eslint import/prefer-default-export: 0 */
import api from '@/modules/api';
import * as Types from '@/api/@types';

export const fetchList = async (): Promise<Array<Types.HeaderNotice>> => {
  const { body } = await api()
    .v1.header_notices.get({
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: [],
        status: 'fail',
      },
    }));

  if (body?.result?.length) {
    return body.result;
  }

  return [];
};

export const getUnreadCount = async (): Promise<number> => {
  const { body } = await api()
    .v1.header_notices.unread_count.get({
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: {
          count: 0,
        },
        status: 'fail',
      },
    }));

  if (body?.result?.count) {
    return body?.result?.count;
  }

  return 0;
};

export const read = (information: Types.HeaderNotice): void => {
  const post = async () => {
    await api()
      .v1.header_notices._id(information.id.toString())
      .read.post({
        body: {
          disp_type: information.disp_type ? 1 : 0,
        },
        config: {
          withCredentials: true,
        },
      })
      .catch(async () => ({}));
  };
  post().finally(() => null);
};
