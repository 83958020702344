import { useState, useCallback } from 'react';
import Link from 'next/link';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';

import { IconButton } from '@mui/material';
import * as Device from 'react-device-detect';

type StyleProps = {
  isHover: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      '&.MuiButtonBase-root': {
        position: 'relative',
        padding: '8px',
        fontFamily: 'ヒラギノ角ゴシック, "Hiragino Sans"',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '0 !important',
        [theme.breakpoints.up('md')]: {
          width: '64px',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          '& svg': {
            fill: '#bf0000',
          },
          '& .label': {
            color: '#bf0000',
          },
        },
        '&.MuiButtonBase-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: '0 !important',
          [theme.breakpoints.up('md')]: {
            width: '64px',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
        zIndex: theme.zIndex.drawer + 1,
        '& svg': {
          display: 'block',
          height: '28px',
          width: '28px',
          fill: '#828282',
          [theme.breakpoints.up('md')]: {
            height: '24px',
            width: '24px',
            fill: '#626262',
          },
        },
        '& .label': {
          marginTop: '4px',
          whiteSpace: 'nowrap',
          color: '#828282',
          fontSize: '10px',
          display: 'none',
          [theme.breakpoints.up('md')]: {
            display: 'block',
            color: '#626262',
          },
        },
        '& .badge': {
          display: 'flex',
          position: 'absolute',
          top: '0',
          right: '-2px',
          color: '#fff',
          [theme.breakpoints.up('md')]: {
            top: '-4px',
            right: '8px',
          },
          backgroundColor: '#bf0000',
          fontSize: '10px',
          minWidth: '18px',
          height: '18px',
          border: '2px solid #fff',
          borderRadius: '16px',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& .subMenu': {
          transition: 'transform 0.2s ease, opacity 0.1s linear',
          transform: (props: StyleProps) => (props.isHover ? 'translateY(0)' : 'translateY(-6px)'),
          opacity: (props: StyleProps) => (props.isHover ? '1' : '0'),
          visibility: (props: StyleProps) => (props.isHover ? 'visible' : 'hidden'),

          paddingTop: '9px',
          [theme.breakpoints.up('md')]: {
            paddingTop: '11px',
          },
          position: 'absolute',
          zIndex: 999,
          top: '40px',
          cursor: 'default',
        },
      },
    },
  }),
);

type Props = {
  icon: JSX.Element;
  label: string;
  href?: string;
  isBadgeVisible?: boolean;
  isSubMenuEnable?: boolean;
  badge?: string | number;
  children?: React.ReactNode;
  className?: string;
  subMenuClassName?: string;
  onClick?: () => void;
  onSubMenuOpen?: () => void;
  onSubMenuClose?: () => void;
};
const NaviItemBase: React.FC<Props> = ({
  icon,
  label,
  href,
  isBadgeVisible,
  isSubMenuEnable = true,
  badge,
  children,
  className,
  subMenuClassName,
  onClick,
  onSubMenuOpen,
  onSubMenuClose,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const classes = useStyles({ isHover });

  const onClickMenu = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (!Device.isMobile) {
      return;
    }
    if (isHover) {
      setIsHover(false);
      if (isSubMenuEnable && children) {
        onSubMenuClose && onSubMenuClose();
      }
    } else {
      setIsHover(true);
      if (isSubMenuEnable && children) {
        onSubMenuOpen && onSubMenuOpen();
      }
    }
  }, [isHover, setIsHover, isSubMenuEnable, children, onClick, onSubMenuOpen, onSubMenuClose]);

  const onMouseEnter = useCallback(() => {
    if (Device.isMobile) {
      return;
    }
    setIsHover(true);
    if (isSubMenuEnable && children) {
      onSubMenuOpen && onSubMenuOpen();
    }
  }, [setIsHover, isSubMenuEnable, children, onSubMenuOpen]);

  const onMouseLeave = useCallback(() => {
    setIsHover(false);
    if (isSubMenuEnable && children) {
      onSubMenuClose && onSubMenuClose();
    }
  }, [setIsHover, isSubMenuEnable, children, onSubMenuClose]);

  if (href) {
    return (
      <Link href={href}>
        <IconButton className={`${classes.root} ${className || ''}`} aria-label={label}>
          {icon}
          <span className="label">{label}</span>
        </IconButton>
      </Link>
    );
  }

  return (
    <>
      {isHover && Device.isMobile && (
        <Backdrop className={classes.backdrop} invisible={true} open={true} onClick={onMouseLeave} />
      )}
      <IconButton
        onClick={onClickMenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`${classes.root} ${className || ''}`}
        aria-label={label}
      >
        {icon}
        <span className="label">{label}</span>
        {isBadgeVisible && <span className="badge">{badge}</span>}

        {isSubMenuEnable && children && <div className={`subMenu ${subMenuClassName ?? ''}`}>{children}</div>}
      </IconButton>
    </>
  );
};

export default NaviItemBase;
