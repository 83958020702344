import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import PurchaseHistoryOutline from '@/assets/icons/rex/PurchaseHistoryOutlined.svg';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const URL = `${NEXT_PUBLIC_RTV_WEB_URL ?? ''}/purchase_history/all`;

type Props = {
  className?: string;
};
const PurchaseHistory: React.FC<Props> = ({ className }) => (
  <NaviItemBase href={URL} icon={<PurchaseHistoryOutline />} label="購入履歴" className={className} />
);

export default PurchaseHistory;
