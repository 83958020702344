import { makeStyles, createStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import Image from '@/modules/components/image';

const useStyles = makeStyles(() =>
  createStyles({
    snsWidgets: {
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '400px',
      margin: '0 auto',
    },
    item: {
      '& .sns-widget__icon': {
        width: '32px',
        height: '32px',
      },
    },
  }),
);

const { NEXT_PUBLIC_IM_URL } = process.env;

const SnsWidgets: React.FC = () => {
  const classes = useStyles();
  const { asPath } = useRouter();
  let twitterLink = 'https://twitter.com/rakutentv_japan/';
  if (asPath.includes('/pacificleague/')) twitterLink = 'https://twitter.com/Rakuten_Pacific';

  return (
    <ul className={classes.snsWidgets}>
      <li className={classes.item}>
        <a href={twitterLink} target="_blank" className="sns-widget__link" rel="noreferrer noopener">
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-x-circle_light.svg`}
            alt="Xボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
      <li className={classes.item}>
        <a
          href="https://www.facebook.com/rakutentvjapan"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-facebook-circle.svg`}
            alt="Facebookボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
      <li className={classes.item}>
        <a
          href="https://www.instagram.com/rakutentv_japan/?hl=ja"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-instagram-circle.svg`}
            alt="Instagramボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
      <li className={classes.item}>
        <a
          href="https://www.threads.net/@rakutentv_japan"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-threads-white.svg`}
            alt="Threadsスレッド"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>

      <li className={classes.item}>
        <a
          href="https://tv.rakuten.co.jp/static/cpn/lineat/"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-line-circle.svg`}
            alt="LINEボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
      <li className={classes.item}>
        <a
          href="https://www.youtube.com/channel/UCPOn94im-2KxOcZW6h9x-vw"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-youtube-circle.svg`}
            alt="YouTubeボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
      <li className={classes.item}>
        <a
          href="https://www.tiktok.com/@rakutentv_japan"
          target="_blank"
          className="sns-widget__link"
          rel="noreferrer noopener"
        >
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-tiktok-circle-white.svg`}
            alt="TikTokボタン"
            className="sns-widget__icon"
            width={32}
            height={32}
          />
        </a>
      </li>
    </ul>
  );
};

export default SnsWidgets;
