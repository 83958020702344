import { useCallback } from 'react';
import Divider from '@mui/material/Divider';
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as Device from 'react-device-detect';

import { useAuthStateContext } from '@/modules/auth/providers';
import CookieUtils, { COOKIES_NAME } from '@/modules/cookies';

import IconHome from '@/assets/icons/left-navi/Home.svg';
// TODO 復活予定
import IconLive from '@/assets/icons/left-navi/Live.svg';
import IconRanking from '@/assets/icons/left-navi/Ranking.svg';
import IconFree from '@/assets/icons/left-navi/Free.svg';
import IconSubscribe from '@/assets/icons/left-navi/Subscribe.svg';
import IconCampaign from '@/assets/icons/left-navi/Campaign.svg';
import IconCalendar from '@/assets/icons/left-navi/Calendar.svg';
import IconRchannel from '@/assets/icons/left-navi/Rch.svg';
import IconBaseball from '@/assets/icons/left-navi/Baseball.svg';
import MyPageOutlined from '@/assets/icons/left-navi/MyPageOutlined.svg';

const useStyles = makeStyles(() =>
  createStyles({
    leftNavi: {
      width: '240px',
      paddingBottom: '80px',
      '& .MuiDivider-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    },
    list: {
      width: 250,
      paddingTop: 64,
    },
    listUnit: {},
    listItem: {
      '&.MuiButtonBase-root': {
        padding: '9px 24px',
      },
    },
    listIcon: {
      '&.MuiListItemIcon-root': {
        minWidth: '24px',
        width: '24px',
        marginRight: '8px',
      },
    },
    listText: {
      '&.MuiListItemText-root': {
        margin: 0,
        color: '#fff',
        '& .MuiTypography-root': {
          fontSize: '14px',
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const { NEXT_PUBLIC_RTV_SPORTS_URL } = process.env;
const { NEXT_PUBLIC_FAQ_NET } = process.env;
const { NEXT_PUBLIC_APP_ENV } = process.env;

const LeftNavi: React.FC = () => {
  const classes = useStyles();
  const { isAuthenticated, ratingAge } = useAuthStateContext();

  const onLogoutHandler = useCallback(() => {
    CookieUtils.setToObject({
      key: 'skipSecond',
      value: '10',
      type: COOKIES_NAME.PLAYER,
    });

    if (NEXT_PUBLIC_APP_ENV === 'development') {
      CookieUtils.destroy({ key: 'TEST_AUTH' });
    }
  }, []);

  return (
    <div className={classes.leftNavi}>
      <List className={classes.listUnit}>
        <ListItemButton
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/`}
          className={`${classes.listItem} ${Device.isMobile ? 'l-id_sp_headergenremenu_top' : 'l-id_global-nav__home'}`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconHome />
          </ListItemIcon>
          <ListItemText primary="楽天TV トップ" className={classes.listText} />
        </ListItemButton>
      </List>
      <Divider />
      <List className={classes.listUnit}>
        <ListItemButton
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_guide' : 'l-id_global-nav__guide'
          }`}
        >
          <ListItemIcon className={classes.listIcon} />
          <ListItemText primary="初めての方" className={classes.listText} />
        </ListItemButton>
        <ListItemButton
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/device/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_watch' : 'l-id_global-nav__watch'
          }`}
        >
          <ListItemIcon className={classes.listIcon} />
          <ListItemText primary="視聴環境" className={classes.listText} />
        </ListItemButton>
        <ListItemButton
          component="a"
          href={`${NEXT_PUBLIC_FAQ_NET}/`}
          className={`${classes.listItem} ${Device.isMobile ? 'l-id_sp_headergenremenu_qa' : 'l-id_global-nav__qa'}`}
        >
          <ListItemIcon className={classes.listIcon} />
          <ListItemText primary="よくある質問" className={classes.listText} />
        </ListItemButton>
        <ListItemButton
          component="a"
          href={`${NEXT_PUBLIC_FAQ_NET}/detail/000001647`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_inquiry' : 'l-id_global-nav__inquiry'
          }`}
        >
          <ListItemIcon className={classes.listIcon} />
          <ListItemText primary="お問い合わせ" className={classes.listText} />
        </ListItemButton>
      </List>
      <Divider />
      {(isAuthenticated || Device.isMobile) && (
        <>
          <List className={classes.listUnit}>
            {isAuthenticated && (
              <ListItem button component="a" href={`${NEXT_PUBLIC_RTV_WEB_URL}/mypage/`} className={classes.listItem}>
                <ListItemIcon className={classes.listIcon}>
                  <MyPageOutlined />
                </ListItemIcon>
                <ListItemText primary="マイページ" className={classes.listText} />
              </ListItem>
            )}
            <Device.MobileView>
              <ListItem
                button
                component="a"
                href={`${NEXT_PUBLIC_RTV_WEB_URL}/config/mobile/player/`}
                className={`${classes.listItem} l-id_sp_headergenremenu_mobile_play`}
              >
                <ListItemIcon className={classes.listIcon} />
                <ListItemText primary="モバイル再生設定" className={classes.listText} />
              </ListItem>
            </Device.MobileView>
          </List>
          <Divider />
        </>
      )}
      <List className={classes.listUnit}>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/ranking/daily/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_ranking' : 'l-id_global-nav__ranking'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconRanking />
          </ListItemIcon>
          <ListItemText primary="ランキング" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/free/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_free' : 'l-id_global-nav__free'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconFree />
          </ListItemIcon>
          <ListItemText primary="無料動画" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/svod/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_svod' : 'l-id_global-nav__svod'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconSubscribe />
          </ListItemIcon>
          <ListItemText primary="定額見放題" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/campaign/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_campaign' : 'l-id_global-nav__campaign'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconCampaign />
          </ListItemIcon>
          <ListItemText primary="キャンペーン" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/schedule/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_schedule' : 'l-id_global-nav__calendar'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconCalendar />
          </ListItemIcon>
          <ListItemText primary="作品配信カレンダー" className={classes.listText} />
        </ListItem>
      </List>
      <Divider />
      <List className={classes.listUnit}>
        <ListItem
          button
          component="a"
          href="https://channel.rakuten.co.jp/?scid=wi_rtv_rchhome&utm_source=rakuteng&utm_medium=banner&utm_campaign=rchhome&utm_content=rtv_gnavi"
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_rchannel' : 'l-id_global-nav__rchannel'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconRchannel />
          </ListItemIcon>
          <ListItemText primary="Rチャンネル" className={classes.listText} />
        </ListItem>
      </List>
      <Divider />
      <ListItem
        button
        component="a"
        href={`${NEXT_PUBLIC_RTV_WEB_URL}/live/`}
        className={`${classes.listItem} ${Device.isMobile ? 'l-id_sp_headergenremenu_live' : 'l-id_global-nav__live'}`}
      >
        <ListItemIcon className={classes.listIcon}>
          <IconLive />
        </ListItemIcon>
        <ListItemText primary="ライブ" className={classes.listText} />
      </ListItem>
      <Divider />
      <List className={classes.listUnit}>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_SPORTS_URL}/pacificleague/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_pacificleague' : 'l-id_global-nav__pacificleague'
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <IconBaseball />
          </ListItemIcon>
          <ListItemText primary="パ・リーグ" className={classes.listText} />
        </ListItem>
      </List>
      <Divider />
      <List className={classes.listUnit}>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/foreign_film/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_foreign_film' : 'l-id_global-nav__genre__foreign-film'
          }`}
        >
          <ListItemText primary="洋画" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/japanese_film/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_japanese_film' : 'l-id_global-nav__genre__japanese-film'
          }`}
        >
          <ListItemText primary="邦画" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/overseas_drama/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_overseas_drama' : 'l-id_global-nav__genre__overseas-drama'
          }`}
        >
          <ListItemText primary="海外ドラマ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/korean_drama/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_korean_drama' : 'l-id_global-nav__genre__korean-drama'
          }`}
        >
          <ListItemText primary="韓国ドラマ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/taiwanese_drama/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_taiwanese_drama' : 'l-id_global-nav__genre__taiwanese-drama'
          }`}
        >
          <ListItemText primary="中国・台湾・タイドラマ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/bl/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_static_bl' : 'l-id_global-nav__genre__static-bl'
          }`}
        >
          <ListItemText primary="BL" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/domestic_drama/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_domestic_drama' : 'l-id_global-nav__genre__domestic-drama'
          }`}
        >
          <ListItemText primary="国内ドラマ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/animation/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_animation' : 'l-id_global-nav__genre__animation'
          }`}
        >
          <ListItemText primary="アニメ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/kids/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_kids' : 'l-id_global-nav__genre__kids'
          }`}
        >
          <ListItemText primary="キッズ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/stage/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_stage' : 'l-id_global-nav__genre__stage'
          }`}
        >
          <ListItemText primary="宝塚" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/stage/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_static_stage' : 'l-id_global-nav__genre__static-stage'
          }`}
        >
          <ListItemText primary="舞台" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/idol_gravure/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_idol_gravure' : 'l-id_global-nav__genre__idol_gravure'
          }`}
        >
          <ListItemText primary="グラビア" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/sports/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_sports' : 'l-id_global-nav__genre__sports'
          }`}
        >
          <ListItemText primary="スポーツ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/variety/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_variety' : 'l-id_global-nav__genre__variety'
          }`}
        >
          <ListItemText primary="バラエティ" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/music/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_music' : 'l-id_global-nav__genre__music'
          }`}
        >
          <ListItemText primary="ミュージック" className={classes.listText} />
        </ListItem>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/documentary/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_documentary' : 'l-id_global-nav__genre__documentary'
          }`}
        >
          <ListItemText primary="ドキュメンタリー" className={classes.listText} />
        </ListItem>
        {ratingAge === 99 && (
          <>
            <ListItem
              button
              component="a"
              href={`${NEXT_PUBLIC_RTV_WEB_URL}/genre/adult/`}
              className={`${classes.listItem} ${
                Device.isMobile ? 'l-id_sp_headergenremenu_adult' : 'l-id_global-nav__genre__adult'
              }`}
              rel="nofollow"
            >
              <ListItemText primary="アダルト" className={classes.listText} />
            </ListItem>
            <ListItem
              button
              component="a"
              href={`${NEXT_PUBLIC_RTV_WEB_URL}/adult/av_mihoudai/`}
              className={`${classes.listItem} ${
                Device.isMobile ? 'l-id_sp_headergenremenu_adultavsvod	' : 'l-id_global-nav__genre__adultavsvod'
              }`}
              rel="nofollow"
            >
              <ListItemText primary="AV見放題" className={classes.listText} />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      <List className={classes.listUnit}>
        <ListItem
          button
          component="a"
          href={`${NEXT_PUBLIC_FAQ_NET}/`}
          className={`${classes.listItem} ${
            Device.isMobile ? 'l-id_sp_headergenremenu_help' : 'l-id_global-nav__genre__help'
          }`}
        >
          <ListItemText primary="ヘルプ" className={classes.listText} />
        </ListItem>
        {isAuthenticated && (
          <ListItem
            button
            component="a"
            href={`${NEXT_PUBLIC_RTV_WEB_URL}/logout/`}
            onClick={onLogoutHandler}
            className={classes.listItem}
          >
            <ListItemText primary="ログアウト" className={classes.listText} />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default LeftNavi;
