/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import LoginIcon from '@/assets/icons/rex/Login.svg';

const { NEXT_PUBLIC_APP_ENV } = process.env;
const { NEXT_PUBLIC_RTV_AUTH_URL } = process.env;
const LOGIN_URL = `${NEXT_PUBLIC_RTV_AUTH_URL ?? ''}/gw/rakuten/pre_login?bu=`;
const LOCAL_LOGIN_URL = '/dev/login?bu=';

type Props = {
  className?: string;
};
const Login: React.FC<Props> = ({ className }) => {
  const [href, setHref] = useState<string>('');
  const router = useRouter();

  useEffect(() => {
    setHref(
      `${NEXT_PUBLIC_APP_ENV === 'development' ? LOCAL_LOGIN_URL : LOGIN_URL}${encodeURIComponent(
        window?.location?.href,
      )}`,
    );
  }, [router.asPath]);

  return <NaviItemBase href={href} icon={<LoginIcon />} label="ログイン" className={className} />;
};

export default Login;
