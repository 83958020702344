import { useState, useCallback } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';

import useSuggestion from '@/modules/hooks/use-suggestion';
import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import SearchIcon from '@/assets/icons/rex/Search.svg';
import { SEARCH_OPTIONS } from '@/modules/constants';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& > div': {
        background: '#fff !important',
      },
      '& .headerUtility': {
        padding: '12px',
        borderBottom: '1px solid #e8e8e8',
        '& > form ul': {
          position: 'relative',
          display: 'flex',
          frexWrap: 'nowrap',
          width: '100%',
          '& #searchForm': {
            position: 'relative',
            padding: '1px 0 1px 1px',
            border: 'solid 1px #bf0000',
            borderRight: 0,
            borderRadius: '4px 0 0 4px',
            flex: 1,
            '& #searchBox': {
              width: '100%',
              height: '36px',
              overflow: 'hidden',
              boxSizing: 'border-box',
              padding: '6px 10px',
              border: 'none',
              borderRadius: '4px 0 0 4px',
              fontSize: '.9rem',
              lineHeight: 1,
              '-webkit-appearance': 'none',
              appearance: 'none',
            },
          },
          '& #serchButton': {
            borderRadius: '0 4px 4px 0',
            backgroundColor: '#bf0000',
            '& button[type=submit]': {
              display: 'block',
              height: '40px',
              width: '40px',
              border: 'none',
              borderRadius: '2px',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              appearance: 'none',
              '& .icon': {
                '& svg': {
                  fill: '#fff',
                  width: '20px',
                  height: '20px',
                },
              },
            },
          },
          '& #btnSearchCancel': {
            width: '70px',
            flexShrink: 0,
            textAlign: 'right',
            '& .txt_searchCancel': {
              color: '#bf0000',
              fontSize: '13px',
              lineHeight: '40px',
              whiteSpace: 'nowrap',
            },
          },
        },
        '& #searchSelect': {
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          margin: '12px 0 0',
          color: '#686868',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& .wrapSelect': {
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            boxSizing: 'border-box',
            '& select': {
              fontSize: '13px',
              width: '100%',
              height: '30px',
              paddingLeft: '10px',
              borderColor: '#ccc',
              borderRadius: '3px',
              '-webkit-appearance': 'none',
              backgroundColor: '#fff',
              color: '#686868',
            },
            '&:after': {
              position: 'absolute',
              top: '50%',
              right: '16px',
              width: '7px',
              height: '7px',
              marginTop: '-5px',
              marginLeft: '3px',
              borderTop: '1px solid #8e8e8e',
              borderRight: '1px solid #8e8e8e',
              content: "''",
              transform: 'rotate(135deg)',
            },
          },
          '& .altText': {
            display: 'inline-block',
            width: '70px',
            fontSize: '13px',
            whiteSpace: 'nowrap',
            flexShrink: 0,
            textAlign: 'right',
          },
        },
      },
      '& .suggestionList': {
        color: '#333',
        padding: '8px 16px',
        '& .item': {
          borderBottom: '1px solid #e2e2e2',
          '& > a': {
            display: 'block',
            color: '#222',
            '& span': {
              display: 'block',
              padding: '10px 3px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '15px',
            },
          },
        },
      },
    },
  }),
);

type Props = {
  className?: string;
};
const SearchMenu: React.FC<Props> = ({ className = '' }) => {
  const classes = useStyles();
  const [isOpenModal, setModalState] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const suggestions = useSuggestion(searchText);

  const onOpenModal = useCallback(() => {
    setModalState(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalState(false);
  }, []);

  const onChangeSearchText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const onClickSuggestion = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      setSearchText((event as unknown as React.ChangeEvent<HTMLInputElement>).target.textContent);
    },
    [],
  );

  return (
    <>
      <NaviItemBase icon={<SearchIcon />} label="検索" className={className} onClick={onOpenModal} />
      <Modal open={isOpenModal} className={classes.modal}>
        <>
          <div className="searchModalIn">
            <div className="headerUtility">
              <form id="search" action={`${NEXT_PUBLIC_RTV_WEB_URL}/search/`} method="get">
                <ul>
                  <li id="searchForm">
                    <span className="sggstInputWrap">
                      <input
                        type="text"
                        name="keyword"
                        maxLength={100}
                        id="searchBox"
                        placeholder="すべての作品から検索"
                        value={searchText}
                        onChange={onChangeSearchText}
                        autoComplete="off"
                      />
                    </span>
                    <div id="search_clear_btn" className="modal-search_delKeyword">
                      <span className="preview__icon">
                        <span className="rex-icon x" />
                      </span>
                    </div>
                  </li>
                  <li id="serchButton">
                    <input type="hidden" name="frm" value="1" />
                    <button type="submit" id="searchBtn">
                      <span className="icon">
                        <SearchIcon />
                      </span>
                    </button>
                  </li>
                  <li id="btnSearchCancel" className="searchCancel">
                    <span
                      className="txt_searchCancel"
                      onClick={onCloseModal}
                      onKeyDown={() => null}
                      role="button"
                      tabIndex={0}
                    >
                      キャンセル
                    </span>
                  </li>
                </ul>
                <div id="searchSelect">
                  <div className="wrapSelect">
                    <select name="genre_code" id="searchMenu">
                      {SEARCH_OPTIONS.map((v) => (
                        <option key={v.value} value={v.value}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="altText">で絞り込む</span>
                </div>
              </form>
            </div>
            {suggestions.length > 1 && (
              <ul className="suggestionList">
                {suggestions.map((suggestion) => (
                  <li className="item" key={suggestion}>
                    <a onClick={onClickSuggestion} onKeyDown={() => null} role="button" tabIndex={0}>
                      {suggestion}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};

export default SearchMenu;
