import React, { forwardRef, useState, useCallback, useRef } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';

type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  fallbackImageType?: 'main' | 'jacket';
  fallbackImageUrl?: string;
  src: string;
  width: number | `${number}`;
  height: number | `${number}`;
};

const Image = forwardRef<HTMLImageElement, ImageProps>((props: ImageProps, _ref) => {
  const {
    fallbackImageType,
    fallbackImageUrl,
    src,
    onClick,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unoptimized = true,
    alt = 'image',
    ...restProps
  } = props;

  const [isLoadedError, setIsLoadedError] = useState<boolean>(false);
  const refIsDelay = useRef<boolean>(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      if (!onClick) return;
      if (refIsDelay.current) return;

      refIsDelay.current = true;
      setTimeout(() => {
        refIsDelay.current = false;
      }, 500);

      onClick(e);
    },
    [onClick],
  );

  const handleError = useCallback(() => setIsLoadedError(true), []);

  if (!src) return null;

  return (
    <NextImage
      src={
        isLoadedError
          ? fallbackImageType
            ? `/images/fallout_image_${fallbackImageType}.png`
            : fallbackImageUrl ?? ''
          : src
      }
      unoptimized={unoptimized}
      alt={alt}
      onClick={handleClick}
      onError={handleError}
      {...restProps}
    />
  );
});

export default Image;
