import { makeStyles, createStyles } from '@mui/styles';

import Image from '@/modules/components/image';

const useStyles = makeStyles(() =>
  createStyles({
    universalLinksForiOS: {
      textAlign: 'center',
      fontSize: '13px',
      color: '#999FA4',
      '& > *': { marginTop: '16px !important' },
    },
    appDownloadBtn: {
      display: 'inline-block',
    },
    qrCode: {
      display: 'inline-block',
    },
    sendByEmail: {
      display: 'inline-block',
    },
  }),
);

const { NEXT_PUBLIC_IM_URL } = process.env;
const { NEXT_PUBLIC_APP_STORE_LINK } = process.env;

const UniversalLinksForiOS: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.universalLinksForiOS}>
      <p>iOS版アプリ</p>
      <a className={classes.appDownloadBtn} href={NEXT_PUBLIC_APP_STORE_LINK}>
        <Image src={`${NEXT_PUBLIC_IM_URL}/img/pc/parts/app-store_104x38.png`} alt="iOS版" width={104} height={38} />
      </a>
      <br />
      <Image
        className={classes.qrCode}
        src={`${NEXT_PUBLIC_IM_URL}/img/pc/parts/ios_qr.png`}
        width={76}
        height={76}
        alt="QRコード"
      />
      <br />
      <a
        className={classes.sendByEmail}
        href={`mailto:?subject=Rakuten appli download&amp;body=${NEXT_PUBLIC_APP_STORE_LINK}`}
      >
        メールでURLを送る
      </a>
    </div>
  );
};

export default UniversalLinksForiOS;
