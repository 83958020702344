import { useState, useEffect } from 'react';
import fetchJsonp from 'fetch-jsonp';

type SuggestionsType = {
  query: string;
  status: string;
  suggestions: {
    terms: string;
  }[];
};

const fetchSuggestions = async (text: string): Promise<string[]> => {
  const ret = await fetchJsonp(
    `https://rdc-api-catalog-gateway-api.rakuten.co.jp/gsp-ac/autocomplete/v1/autocomplete.jp_showtime_item?format=jsonp&q=${text}&sid=jp_showtime_ac_item_001&_=${new Date()
      .getMilliseconds()
      .toString()}`,
  );

  if (!ret || !ret.ok) {
    return null;
  }

  const suggestions = await ret.json<SuggestionsType>().catch(() => null as SuggestionsType);

  return suggestions?.suggestions?.map((s) => s.terms);
};

const useSuggestion = (text: string): string[] => {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const ret = await fetchSuggestions(text);
      setSuggestions(ret);
    };
    fetchData().finally(() => null);
  }, [text]);

  return suggestions;
};

export default useSuggestion;
