/* eslint-disable */
import type { AspidaClient } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods0 } from './_version@string/chat-blocks'
import type { Methods as Methods1 } from './v1/bad-behavior-reports/sendbird'
import type { Methods as Methods2 } from './v1/banners'
import type { Methods as Methods3 } from './v1/chat-blocks'
import type { Methods as Methods4 } from './v1/contents'
import type { Methods as Methods5 } from './v1/contents/_id@string'
import type { Methods as Methods6 } from './v1/contents/_id@string/ams-token'
import type { Methods as Methods7 } from './v1/contents/_id@string/live'
import type { Methods as Methods8 } from './v1/contents/_id@string/live-cached'
import type { Methods as Methods9 } from './v1/contents/_id@string/playinfo'
import type { Methods as Methods10 } from './v1/contents/_id@string/related'
import type { Methods as Methods11 } from './v1/contents/chat-info/_id@string'
import type { Methods as Methods12 } from './v1/contents/chat-polling/_id@string'
import type { Methods as Methods13 } from './v1/contents/followed'
import type { Methods as Methods14 } from './v1/contents/repeat-schedule'
import type { Methods as Methods15 } from './v1/contents/schedule'
import type { Methods as Methods16 } from './v1/genres'
import type { Methods as Methods17 } from './v1/header-notices'
import type { Methods as Methods18 } from './v1/header-notices/_id@string/read'
import type { Methods as Methods19 } from './v1/header-notices/unread-count'
import type { Methods as Methods20 } from './v1/member'
import type { Methods as Methods21 } from './v1/sliders'
import type { Methods as Methods22 } from './v1/top-notices'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:8000/' : baseURL).replace(/\/$/, '')
  const PATH0 = '/chat-blocks'
  const PATH1 = '/v1/bad-behavior-reports/sendbird'
  const PATH2 = '/v1/banners'
  const PATH3 = '/v1/chat-blocks'
  const PATH4 = '/v1/contents'
  const PATH5 = '/ams-token'
  const PATH6 = '/live'
  const PATH7 = '/live-cached'
  const PATH8 = '/playinfo'
  const PATH9 = '/related'
  const PATH10 = '/v1/contents/chat-info'
  const PATH11 = '/v1/contents/chat-polling'
  const PATH12 = '/v1/contents/followed'
  const PATH13 = '/v1/contents/repeat-schedule'
  const PATH14 = '/v1/contents/schedule'
  const PATH15 = '/v1/genres'
  const PATH16 = '/v1/header-notices'
  const PATH17 = '/read'
  const PATH18 = '/v1/header-notices/unread-count'
  const PATH19 = '/v1/member'
  const PATH20 = '/v1/sliders'
  const PATH21 = '/v1/top-notices'
  const GET = 'GET'
  const POST = 'POST'
  const DELETE = 'DELETE'

  return {
    _version: (val0: string) => {
      const prefix0 = `/${val0}`

      return {
        chat_blocks: {
          /**
           * @returns Return the block user list successfully.
           */
          get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
            fetch<Methods0['get']['resBody'], Methods0['get']['resHeaders'], Methods0['get']['status']>(prefix, `${prefix0}${PATH0}`, GET, option).json(),
          /**
           * @returns Return the block user list successfully.
           */
          $get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
            fetch<Methods0['get']['resBody'], Methods0['get']['resHeaders'], Methods0['get']['status']>(prefix, `${prefix0}${PATH0}`, GET, option).json().then(r => r.body),
          /**
           * @returns Successfully delete the blocked user.
           */
          delete: (option: { query: Methods0['delete']['query'], config?: T | undefined }) =>
            fetch<Methods0['delete']['resBody'], Methods0['delete']['resHeaders'], Methods0['delete']['status']>(prefix, `${prefix0}${PATH0}`, DELETE, option).json(),
          /**
           * @returns Successfully delete the blocked user.
           */
          $delete: (option: { query: Methods0['delete']['query'], config?: T | undefined }) =>
            fetch<Methods0['delete']['resBody'], Methods0['delete']['resHeaders'], Methods0['delete']['status']>(prefix, `${prefix0}${PATH0}`, DELETE, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | { method: 'delete'; query: Methods0['delete']['query'] } | undefined) =>
            `${prefix}${prefix0}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        }
      }
    },
    v1: {
      bad_behavior_reports: {
        sendbird: {
          /**
           * @returns Successfully created.
           */
          post: (option: { body: Methods1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods1['post']['resBody'], Methods1['post']['resHeaders'], Methods1['post']['status']>(prefix, PATH1, POST, option).json(),
          /**
           * @returns Successfully created.
           */
          $post: (option: { body: Methods1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods1['post']['resBody'], Methods1['post']['resHeaders'], Methods1['post']['status']>(prefix, PATH1, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH1}`
        }
      },
      banners: {
        /**
         * @returns get banner list successfully.
         */
        get: (option?: { query?: Methods2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods2['get']['resBody'], Methods2['get']['resHeaders'], Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
        /**
         * @returns get banner list successfully.
         */
        $get: (option?: { query?: Methods2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods2['get']['resBody'], Methods2['get']['resHeaders'], Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods2['get']['query'] } | undefined) =>
          `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      chat_blocks: {
        /**
         * @returns Successfully blocked the user.
         */
        post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods3['post']['resBody'], Methods3['post']['resHeaders'], Methods3['post']['status']>(prefix, PATH3, POST, option).json(),
        /**
         * @returns Successfully blocked the user.
         */
        $post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods3['post']['resBody'], Methods3['post']['resHeaders'], Methods3['post']['status']>(prefix, PATH3, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH3}`
      },
      contents: {
        _id: (val2: string) => {
          const prefix2 = `${PATH4}/${val2}`

          return {
            ams_token: {
              /**
               * @returns Generate ams token to fetch DRM License for the given content and drm type
               */
              get: (option: { query: Methods6['get']['query'], config?: T | undefined }) =>
                fetch<Methods6['get']['resBody'], Methods6['get']['resHeaders'], Methods6['get']['status']>(prefix, `${prefix2}${PATH5}`, GET, option).json(),
              /**
               * @returns Generate ams token to fetch DRM License for the given content and drm type
               */
              $get: (option: { query: Methods6['get']['query'], config?: T | undefined }) =>
                fetch<Methods6['get']['resBody'], Methods6['get']['resHeaders'], Methods6['get']['status']>(prefix, `${prefix2}${PATH5}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods6['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            live: {
              /**
               * @returns Return the polling info successfully.
               */
              get: (option: { query: Methods7['get']['query'], config?: T | undefined }) =>
                fetch<Methods7['get']['resBody'], Methods7['get']['resHeaders'], Methods7['get']['status']>(prefix, `${prefix2}${PATH6}`, GET, option).json(),
              /**
               * @returns Return the polling info successfully.
               */
              $get: (option: { query: Methods7['get']['query'], config?: T | undefined }) =>
                fetch<Methods7['get']['resBody'], Methods7['get']['resHeaders'], Methods7['get']['status']>(prefix, `${prefix2}${PATH6}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods7['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            live_cached: {
              /**
               * @returns Return the polling info successfully.
               */
              get: (option: { query: Methods8['get']['query'], config?: T | undefined }) =>
                fetch<Methods8['get']['resBody'], Methods8['get']['resHeaders'], Methods8['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json(),
              /**
               * @returns Return the polling info successfully.
               */
              $get: (option: { query: Methods8['get']['query'], config?: T | undefined }) =>
                fetch<Methods8['get']['resBody'], Methods8['get']['resHeaders'], Methods8['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods8['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            playinfo: {
              /**
               * @returns Return the playinfo and playback manifest details of the selected content
               */
              get: (option: { query: Methods9['get']['query'], config?: T | undefined }) =>
                fetch<Methods9['get']['resBody'], Methods9['get']['resHeaders'], Methods9['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json(),
              /**
               * @returns Return the playinfo and playback manifest details of the selected content
               */
              $get: (option: { query: Methods9['get']['query'], config?: T | undefined }) =>
                fetch<Methods9['get']['resBody'], Methods9['get']['resHeaders'], Methods9['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods9['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            related: {
              /**
               * @returns Return the polling info successfully.
               */
              get: (option: { query: Methods10['get']['query'], config?: T | undefined }) =>
                fetch<Methods10['get']['resBody'], Methods10['get']['resHeaders'], Methods10['get']['status']>(prefix, `${prefix2}${PATH9}`, GET, option).json(),
              /**
               * @returns Return the polling info successfully.
               */
              $get: (option: { query: Methods10['get']['query'], config?: T | undefined }) =>
                fetch<Methods10['get']['resBody'], Methods10['get']['resHeaders'], Methods10['get']['status']>(prefix, `${prefix2}${PATH9}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods10['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            /**
             * @returns Return a content successfully.
             */
            get: (option: { query: Methods5['get']['query'], config?: T | undefined }) =>
              fetch<Methods5['get']['resBody'], Methods5['get']['resHeaders'], Methods5['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * @returns Return a content successfully.
             */
            $get: (option: { query: Methods5['get']['query'], config?: T | undefined }) =>
              fetch<Methods5['get']['resBody'], Methods5['get']['resHeaders'], Methods5['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods5['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          }
        },
        chat_info: {
          _id: (val3: string) => {
            const prefix3 = `${PATH10}/${val3}`

            return {
              /**
               * @returns Return the chat information successfully.
               */
              get: (option: { query: Methods11['get']['query'], config?: T | undefined }) =>
                fetch<Methods11['get']['resBody'], Methods11['get']['resHeaders'], Methods11['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * @returns Return the chat information successfully.
               */
              $get: (option: { query: Methods11['get']['query'], config?: T | undefined }) =>
                fetch<Methods11['get']['resBody'], Methods11['get']['resHeaders'], Methods11['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods11['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          }
        },
        chat_polling: {
          _id: (val3: string) => {
            const prefix3 = `${PATH11}/${val3}`

            return {
              /**
               * @returns Return the chat polling information successfully.
               */
              get: (option: { query: Methods12['get']['query'], config?: T | undefined }) =>
                fetch<Methods12['get']['resBody'], Methods12['get']['resHeaders'], Methods12['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * @returns Return the chat polling information successfully.
               */
              $get: (option: { query: Methods12['get']['query'], config?: T | undefined }) =>
                fetch<Methods12['get']['resBody'], Methods12['get']['resHeaders'], Methods12['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods12['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          }
        },
        followed: {
          /**
           * @returns get my purchased/bookmark list successfully.
           */
          get: (option: { query: Methods13['get']['query'], config?: T | undefined }) =>
            fetch<Methods13['get']['resBody'], Methods13['get']['resHeaders'], Methods13['get']['status']>(prefix, PATH12, GET, option).json(),
          /**
           * @returns get my purchased/bookmark list successfully.
           */
          $get: (option: { query: Methods13['get']['query'], config?: T | undefined }) =>
            fetch<Methods13['get']['resBody'], Methods13['get']['resHeaders'], Methods13['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods13['get']['query'] } | undefined) =>
            `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        repeat_schedule: {
          /**
           * @returns get repeat schedule successfully.
           */
          get: (option?: { query?: Methods14['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods14['get']['resBody'], Methods14['get']['resHeaders'], Methods14['get']['status']>(prefix, PATH13, GET, option).json(),
          /**
           * @returns get repeat schedule successfully.
           */
          $get: (option?: { query?: Methods14['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods14['get']['resBody'], Methods14['get']['resHeaders'], Methods14['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods14['get']['query'] } | undefined) =>
            `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        schedule: {
          /**
           * @returns get schedule successfully.
           */
          get: (option: { query: Methods15['get']['query'], config?: T | undefined }) =>
            fetch<Methods15['get']['resBody'], Methods15['get']['resHeaders'], Methods15['get']['status']>(prefix, PATH14, GET, option).json(),
          /**
           * @returns get schedule successfully.
           */
          $get: (option: { query: Methods15['get']['query'], config?: T | undefined }) =>
            fetch<Methods15['get']['resBody'], Methods15['get']['resHeaders'], Methods15['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods15['get']['query'] } | undefined) =>
            `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        /**
         * @returns Return the content list in the selected pack
         */
        get: (option: { query: Methods4['get']['query'], config?: T | undefined }) =>
          fetch<Methods4['get']['resBody'], Methods4['get']['resHeaders'], Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
        /**
         * @returns Return the content list in the selected pack
         */
        $get: (option: { query: Methods4['get']['query'], config?: T | undefined }) =>
          fetch<Methods4['get']['resBody'], Methods4['get']['resHeaders'], Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods4['get']['query'] } | undefined) =>
          `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      genres: {
        /**
         * @returns get genre list successfully.
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods16['get']['resBody'], Methods16['get']['resHeaders'], Methods16['get']['status']>(prefix, PATH15, GET, option).json(),
        /**
         * @returns get genre list successfully.
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods16['get']['resBody'], Methods16['get']['resHeaders'], Methods16['get']['status']>(prefix, PATH15, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH15}`
      },
      header_notices: {
        _id: (val2: string) => {
          const prefix2 = `${PATH16}/${val2}`

          return {
            read: {
              /**
               * @returns read successfully.
               */
              post: (option: { body: Methods18['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods18['post']['resBody'], Methods18['post']['resHeaders'], Methods18['post']['status']>(prefix, `${prefix2}${PATH17}`, POST, option).json(),
              /**
               * @returns read successfully.
               */
              $post: (option: { body: Methods18['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods18['post']['resBody'], Methods18['post']['resHeaders'], Methods18['post']['status']>(prefix, `${prefix2}${PATH17}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH17}`
            }
          }
        },
        unread_count: {
          /**
           * @returns get header notice unread count
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods19['get']['resBody'], Methods19['get']['resHeaders'], Methods19['get']['status']>(prefix, PATH18, GET, option).json(),
          /**
           * @returns get header notice unread count
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods19['get']['resBody'], Methods19['get']['resHeaders'], Methods19['get']['status']>(prefix, PATH18, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH18}`
        },
        /**
         * @returns get header notice list successfully.
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods17['get']['resBody'], Methods17['get']['resHeaders'], Methods17['get']['status']>(prefix, PATH16, GET, option).json(),
        /**
         * @returns get header notice list successfully.
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods17['get']['resBody'], Methods17['get']['resHeaders'], Methods17['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH16}`
      },
      member: {
        /**
         * @returns get current member name successfully.
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods20['get']['resBody'], Methods20['get']['resHeaders'], Methods20['get']['status']>(prefix, PATH19, GET, option).json(),
        /**
         * @returns get current member name successfully.
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods20['get']['resBody'], Methods20['get']['resHeaders'], Methods20['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH19}`
      },
      sliders: {
        /**
         * @returns get slider list successfully.
         */
        get: (option?: { query?: Methods21['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], Methods21['get']['resHeaders'], Methods21['get']['status']>(prefix, PATH20, GET, option).json(),
        /**
         * @returns get slider list successfully.
         */
        $get: (option?: { query?: Methods21['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], Methods21['get']['resHeaders'], Methods21['get']['status']>(prefix, PATH20, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods21['get']['query'] } | undefined) =>
          `${prefix}${PATH20}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      top_notices: {
        /**
         * @returns get notice list successfully.
         */
        get: (option?: { query?: Methods22['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], Methods22['get']['resHeaders'], Methods22['get']['status']>(prefix, PATH21, GET, option).json(),
        /**
         * @returns get notice list successfully.
         */
        $get: (option?: { query?: Methods22['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], Methods22['get']['resHeaders'], Methods22['get']['status']>(prefix, PATH21, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods22['get']['query'] } | undefined) =>
          `${prefix}${PATH21}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api