import { useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useIsAuthenticated } from '@/modules/auth/providers';
import CookieUtils, { COOKIES_NAME } from '@/modules/cookies';

import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import SettingsOutlined from '@/assets/icons/rex/SettingsOutlined.svg';
import MyPageOutlined from '@/assets/icons/rex/MyPageOutlined.svg';
import DesktopOutlined from '@/assets/icons/rex/DesktopOutlined.svg';
import NewUser from '@/assets/icons/rex/NewUser.svg';
import HelpIcon from '@/assets/icons/rex/HelpOutlined.svg';
import Logout from '@/assets/icons/rex/Logout.svg';
import Sitemap from '@/assets/icons/Sitemap.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subMenu: {
      right: '-24px',
      '& .subMenuSetting': {
        right: 0,
        '& .arrow': {
          position: 'absolute',
          top: '3px',
          right: '50px',
          width: '12px',
          height: '12px',
          border: '1px solid',
          borderColor: '#d1d1d1 #d1d1d1 transparent transparent',
          transform: 'rotate(-45deg)',
          backgroundColor: '#FFF',
          [theme.breakpoints.up('md')]: {
            top: '5px',
          },
        },
        '& .inner': {
          [theme.breakpoints.up('md')]: {
            minWidth: '180px',
          },
          padding: '6px 0',
          border: '1px solid #d1d1d1',
          backgroundColor: '#FFF',
          '& li': {
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
              padding: '0 20px',
            },
            '& a': {
              padding: '6px 0',
              display: 'flex',
              alignItems: 'start',
              '& .icon svg': {
                display: 'block',
                height: '20px',
                width: '20px',
                fill: '#626262',
              },
              '&:hover .icon svg': {
                fill: '#bf0000',
              },
              '& .txt': {
                padding: '0 0 0 8px',
                color: '#626262',
                fontSize: '12px',
                whiteSpace: 'nowrap',
              },
              '&:hover .txt': {
                color: '#bf0000',
              },
            },
          },
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const { NEXT_PUBLIC_FAQ_NET } = process.env;
const { NEXT_PUBLIC_APP_ENV } = process.env;

const SettingSubMenu: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();

  const onLogoutHandler = useCallback(() => {
    CookieUtils.setToObject({
      key: 'skipSecond',
      value: '10',
      type: COOKIES_NAME.PLAYER,
    });

    if (NEXT_PUBLIC_APP_ENV === 'development') {
      CookieUtils.destroy({ key: 'TEST_AUTH' });
    }
  }, []);

  return (
    <div className="subMenuSetting">
      <span className="arrow" />
      <div className="inner">
        <ul>
          {isAuthenticated && (
            <li>
              <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/mypage/`}>
                <span className="icon">
                  <MyPageOutlined />
                </span>
                <span className="txt">マイページ</span>
              </a>
            </li>
          )}
          <li>
            <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/device/`}>
              <span className="icon">
                <DesktopOutlined />
              </span>
              <span className="txt">対応デバイス</span>
            </a>
          </li>
          {isAuthenticated && (
            <li>
              <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/`}>
                <span className="icon">
                  <NewUser />
                </span>
                <span className="txt">初めての方</span>
              </a>
            </li>
          )}
          <li>
            <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/sitemap/`}>
              <span className="icon">
                <Sitemap />
              </span>
              <span className="txt">サイトマップ</span>
            </a>
          </li>
          <li>
            <a href={`${NEXT_PUBLIC_FAQ_NET}/`}>
              <span className="icon">
                <HelpIcon />
              </span>
              <span className="txt">ヘルプ</span>
            </a>
          </li>
          {isAuthenticated && (
            <li>
              <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/logout/`} onClick={onLogoutHandler}>
                <span className="icon">
                  <Logout />
                </span>
                <span className="txt">ログアウト</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

type Props = {
  className?: string;
};
const Setting: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <NaviItemBase icon={<SettingsOutlined />} label="設定" className={className} subMenuClassName={classes.subMenu}>
      <SettingSubMenu />
    </NaviItemBase>
  );
};

export default Setting;
