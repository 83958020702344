import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import NewUser from '@/assets/icons/rex/NewUser.svg';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

type Props = {
  className?: string;
};
const BeginnerGuide: React.FC<Props> = ({ className }) => (
  <NaviItemBase
    href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/`}
    icon={<NewUser />}
    label="初めての方"
    className={className}
  />
);

export default BeginnerGuide;
