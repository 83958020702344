/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import aspida from '@aspida/axios';
import api from '@/api/$api';
import qs from 'qs';
////////////
////////////////////////////////////////////
//////////

const { NEXT_PUBLIC_RTV_LIVE_API_URL } = process.env;
const { NEXT_PUBLIC_RTV_LIVE_API_INTERNAL_URL } = process.env;
const { NEXT_PUBLIC_APP_ENV } = process.env;

let apiClient = api(
  aspida(axios, {
    baseURL: typeof window !== 'undefined' ? NEXT_PUBLIC_RTV_LIVE_API_URL : NEXT_PUBLIC_RTV_LIVE_API_INTERNAL_URL,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  }),
);

////////////
///////////////////////////////////////////////
//////////

export const apiWrapper = () => {
  if (NEXT_PUBLIC_APP_ENV !== 'qaload') {
    return apiClient;
  }

  if (typeof window === 'undefined') {
    return apiClient;
  }

  // qaload環境のみ
  // 負荷テスト時に画面URLに付加するパラメータを各APIのクエリパラメータにも付加する
  axios.interceptors.request.use((request) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (!request?.params) {
      request.params = {};
    }
    if (params.get('jmeter')) {
      request.params.jmeter = params.get('jmeter');
    }
    if (params.get('jmeter_easy_id')) {
      request.params.jmeter_easy_id = params.get('jmeter_easy_id');
    }

    return request;
  });

  return apiClient;
};

export default apiWrapper;
