import { useState, useCallback } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import useSuggestion from '@/modules/hooks/use-suggestion';
import SearchIcon from '@/assets/icons/rex/Search.svg';
import { SEARCH_OPTIONS } from '@/modules/constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // [theme.breakpoints.down('sm')]: {
      //   display: 'none',
      // },
      width: '100%',
      height: '40px',
      padding: '0 24px',
      fontSize: '14px',
      flex: '1 1 auto',
      '& #searchArea': {
        position: 'relative',
        maxWidth: '825px',
        width: '100%',
        boxSizing: 'border-box',
        margin: '0 auto',
        '& form#search': {
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          boxSizing: 'border-box',
          '& #searchSelect': {
            position: 'relative',
            display: 'block',
            height: '40px',
            lineHeight: '27px',
            '& select': {
              display: 'block',
              width: '144px',
              height: '40px',
              boxSizing: 'border-box',
              margin: '0',
              padding: '0 0 0 8px',
              outline: 'none',
              border: '1px solid #bf0000',
              borderRight: 'none',
              borderRadius: '4px 0 0 4px',
              background: '#fff',
              color: '#bf0000',
              verticalAlign: 'middle',
              cursor: 'pointer',
              appearance: 'none',
              fontSize: '11px',
              '& option': {
                color: '#333',
              },
            },
            '&::after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: '0',
              bottom: '0',
              right: '8px',
              width: '0',
              height: '0',
              margin: 'auto',
              borderTop: '5px solid #bf0000',
              borderRight: '3.5px solid transparent',
              borderLeft: '3.5px solid transparent',
            },
          },
          '& #searchSelect + #searchInbox': {
            '& #searchBox': {
              borderLeft: '1px solid #bf0000',
              borderRadius: '0',
            },
          },
          '& #searchInbox': {
            width: '100%',
            '& #searchBox': {
              '-webkit-user-select': 'auto',
              padding: '4px 12px',
              borderTop: '1px solid #bf0000',
              borderLeft: '1px solid #bf0000',
              borderRight: 'none',
              borderBottom: '1px solid #bf0000',
              borderRadius: '4px 0 0 4px',
              outline: 'none',
              display: 'block',
              width: '100%',
              height: '40px',
              boxSizing: 'border-box',
              appearance: 'none',
            },
            '& .suggestionList': {
              border: '1px solid #d3d3d3',
              background: '#ffffff',
              color: '#222222',
              fontSize: '14px',
              cursor: 'pointer',
              '& .item': {
                '& > a': {
                  display: 'block',
                  color: '#222',
                  '&:hover': {
                    border: '1px solid #aaa',
                    boxSizing: 'content-box',
                    margin: '-1px',
                  },
                  '& span': {
                    display: 'block',
                    padding: '4px 8px',
                  },
                },
              },
            },
          },
          '& #searchBtn': {
            flexShrink: '0',
            position: 'relative',
            width: '40px',
            height: '40px',
            boxSizing: 'border-box',
            verticalAlign: 'top',
            cursor: 'pointer',
            outline: 'none',
            border: 'none',
            borderRadius: '0px 4px 4px 0px',
            background: 'rgb(191, 0, 0)',
            '& .icon svg': {
              fill: '#fff',
              width: '20px',
              height: '20px',
            },
          },
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

type Props = {
  className?: string;
};
const SearchBox: React.FC<Props> = ({ className = '' }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');
  const suggestions = useSuggestion(searchText);

  const onChangeSearchText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const onClickSuggestion = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      setSearchText((event as unknown as React.ChangeEvent<HTMLInputElement>).target.textContent);
    },
    [],
  );

  return (
    <div className={`${className} ${classes.root}`}>
      <div id="searchArea">
        <form id="search" action={`${NEXT_PUBLIC_RTV_WEB_URL}/search/`} method="get">
          <div id="searchSelect">
            <select name="genre_code" id="searchMenu">
              {SEARCH_OPTIONS.map((v) => (
                <option key={v.value} value={v.value}>
                  {v.label}
                </option>
              ))}
            </select>
          </div>
          <div id="searchInbox">
            <div id="searchform" className="sggstInputWrap">
              <input
                id="searchBox"
                type="text"
                name="keyword"
                placeholder="キーワードから探す"
                maxLength={100}
                className="ui-autocomplete-input"
                value={searchText}
                onChange={onChangeSearchText}
                autoComplete="off"
              />
            </div>
            <input type="hidden" name="frm" value="1" />
            {suggestions.length > 1 && (
              <ul className="suggestionList">
                {suggestions.map((suggestion) => (
                  <li className="item" key={suggestion}>
                    <a onClick={onClickSuggestion} onKeyDown={() => null} role="button" tabIndex={0}>
                      {suggestion}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button type="submit" id="searchBtn">
            <span className="icon">
              <SearchIcon />
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBox;
