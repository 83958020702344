import { useState, useEffect, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import NaviItemBase from '@/modules/layouts/shared/header/navi-item';
import InformationList from '@/modules/layouts/shared/header/information-list';
import * as Types from '@/api/@types';
import * as InformationService from '@/modules/layouts/shared/header/navi-item/information/sevices';
import NoticeUserOutlined from '@/assets/icons/rex/NoticeUserOutlined.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subMenu: {
      right: '-8px',
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        right: '-88px',
        width: 'auto',
      },
      '& .subMenuNotice': {
        '& .arrow': {
          position: 'absolute',
          top: '5px',
          left: 'auto',
          right: '25px',
          [theme.breakpoints.up('md')]: {
            left: 'auto',
            right: 'calc(50% - 136px)',
          },
          width: '12px',
          height: '12px',
          border: '1px solid',
          borderColor: '#d1d1d1 #d1d1d1 transparent transparent',
          transform: 'rotate(-45deg)',
          backgroundColor: '#FFF',
        },
        '& .inner': {
          minWidth: 'auto',
          [theme.breakpoints.up('md')]: {
            minWidth: '500px',
          },
          padding: '6px 0',
          border: '1px solid #d1d1d1',
          backgroundColor: '#FFF',
          '& li': {
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
              padding: '0 20px',
            },
            '& a': {
              padding: '6px 0',
              display: 'flex',
              alignItems: 'start',
              '& .icon svg': {
                display: 'block',
                height: '20px',
                width: '20px',
                fill: '#626262',
              },
              '&:hover .icon svg': {
                fill: '#bf0000',
              },
              '& .txt': {
                padding: '0 0 0 8px',
                color: '#626262',
                fontSize: '12px',
                whiteSpace: 'nowrap',
              },
              '&:hover .txt': {
                color: '#bf0000',
              },
            },
          },
        },
      },
    },
  }),
);

type Props = {
  className?: string;
};
const Information: React.FC<Props> = ({ className = '' }) => {
  const classes = useStyles();
  const [informations, setInformations] = useState<Array<Types.HeaderNotice>>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  // 未読数取得
  const getUnreadCount = useCallback(() => {
    InformationService.getUnreadCount()
      .then((n) => {
        setUnreadCount(n);
      })
      .finally(() => null);
  }, []);

  // メニューを開いたタイミングでリスト取得
  // リスト取得APIにURLの付いていないお知らせを既読にする処理が含まれている
  const onSubMenuOpen = useCallback(() => {
    InformationService.fetchList()
      .then((list) => {
        setInformations(list);
        getUnreadCount();
      })
      .finally(() => null);
  }, [getUnreadCount]);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  return (
    <NaviItemBase
      icon={<NoticeUserOutlined />}
      label="お知らせ"
      isBadgeVisible={unreadCount > 0}
      isSubMenuEnable={true}
      badge={unreadCount}
      className={className}
      subMenuClassName={classes.subMenu}
      onSubMenuOpen={onSubMenuOpen}
    >
      <div className="subMenuNotice">
        <span className="arrow" />
        <div className="inner">
          <InformationList informations={informations} onClickNotice={getUnreadCount} />
        </div>
      </div>
    </NaviItemBase>
  );
};

export default Information;
