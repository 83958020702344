import { makeStyles, createStyles } from '@mui/styles';

import Image from '@/modules/components/image';

const useStyles = makeStyles(() =>
  createStyles({
    universalLinksForAndroid: {
      textAlign: 'center',
      fontSize: '13px',
      color: '#999FA4',
      '& > *': { marginTop: '16px !important' },
    },
    appDownloadBtn: {
      display: 'inline-block',
    },
    qrCode: {
      display: 'inline-block',
    },
    sendByEmail: {
      display: 'inline-block',
    },
  }),
);

const { NEXT_PUBLIC_IM_URL } = process.env;
const { NEXT_PUBLIC_PLAY_STORE_LINK } = process.env;

const UniversalLinksForAndroid: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.universalLinksForAndroid}>
      <p>Android版アプリ</p>
      <a className={classes.appDownloadBtn} href={NEXT_PUBLIC_PLAY_STORE_LINK}>
        <Image
          src={`${NEXT_PUBLIC_IM_URL}/img/pc/parts/google-play_124x38.png`}
          alt="Android版"
          width={124}
          height={38}
        />
      </a>
      <br />
      <Image
        className={classes.qrCode}
        src={`${NEXT_PUBLIC_IM_URL}/img/pc/parts/and_qr.png`}
        width={76}
        height={76}
        alt="QRコード"
      />
      <br />
      <a
        className={classes.sendByEmail}
        href="mailto:?subject=Rakuten appli download&amp;body=https://play.google.com/store/apps/details?id=jp.co.rakuten.video.showtimeplayer&amp;hl=ja"
      >
        メールでURLを送る
      </a>
    </div>
  );
};

export default UniversalLinksForAndroid;
