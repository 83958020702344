import { useRef, useState, useCallback, useEffect } from 'react';
import Link from 'next/link';
import Script from 'next/script';
import { useRouter } from 'next/router';
import * as Device from 'react-device-detect';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Button, IconButton } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useAuthStateContext } from '@/modules/auth/providers';
import MNOBanner from '@/modules/layouts/shared/header/mno-banner';
import LeftNavi from '@/modules/layouts/shared/header/left-navi';
import SearchBox from '@/modules/layouts/shared/header/search-box';
import SearchMenu from '@/modules/layouts/shared/header/navi-item/search';
import PurchaseHistory from '@/modules/layouts/shared/header/navi-item/purchase-history';
import BeginnerGuide from '@/modules/layouts/shared/header/navi-item/beginner-guide';
import Login from '@/modules/layouts/shared/header/navi-item/login';
import Information from '@/modules/layouts/shared/header/navi-item/information';
import Favorite from '@/modules/layouts/shared/header/navi-item/favorite';
import Setting from '@/modules/layouts/shared/header/navi-item/setting-sub-menu';
import Image from '@/modules/components/image';

import Menu from '@/assets/icons/rex/Menu.svg';
import Close from '@/assets/icons/rex/Close.svg';

const { NEXT_PUBLIC_IM_URL } = process.env;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerBanner: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    headerNav: {
      position: 'relative',
      width: '100%',
      height: '20px',
      backgroundColor: '#515151',
      color: '#dfdfdf',
      '& .headerNav-List': {
        display: 'flex',
        padding: '0 calc(24px - 1em)',
        fontSize: '10px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '-webkit-text-size-adjust': 'none',
        '&>li': {
          padding: '0 1em',
          transition: 'background-color .2s linear',
          lineHeight: '20px',
        },
        '& li': {
          '&:hover': {
            backgroundColor: '#282828',
          },
        },
      },
      '& .headerNav-listGroup': {
        position: 'relative',

        '&:hover': {
          '& .headerNav-listGroup-menu': {
            height: 'auto',
            overflow: 'visible',
            opacity: 1,
            transform: 'translateY(0)',
          },
        },

        '&-menu': {
          position: 'absolute',
          top: '20px',
          left: 0,
          width: '180px',
          overflow: 'hidden',
          zIndex: '1000',
          padding: '0.25rem 0',
          backgroundColor: '#515151',
          color: '#dfdfdf',
          transform: 'translateY(-6px)',
          opacity: 0,
          height: 0,
          transition: 'transform .2s ease,opacity .1s linear',
          '&>li': {
            padding: '0.25rem 1rem',
            pointer: 'cursor',
            '& a': {
              width: '100%',
              display: 'block',
            },
          },
        },
        '&-link': {
          '&::after': {
            display: 'inline-block',
            content: "''",
            height: '9px',
            width: '9px',
            borderRight: '1px solid #dfdfdf',
            borderBottom: '1px solid #dfdfdf',
            transform: 'rotate(45deg)',
            marginLeft: '6px',
            marginBottom: '2px',
          },
        },
      },
    },
    header: {
      height: '54px',
      padding: '0 8px',
      position: 'relative',
      zIndex: 999,
      [theme.breakpoints.up('md')]: {
        height: '60px',
        padding: '0 24px 0 16px',
      },
      background: '#FFF',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .leftClm,& .rightClm': {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
      },
      '& .rightClm': {
        '& .MuiIconButton-root': {
          borderRadius: 0,
          zIndex: 1,
        },
      },
      '& .search': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },
    menuButton: {
      '&.MuiButtonBase-root': {
        marginRight: '24px',
        alignSelf: 'center',
        padding: 0,
        '& svg': {
          display: 'block',
          height: '24px',
          width: '24px',
          fill: '#828282',
          [theme.breakpoints.up('md')]: {
            fill: '#626262',
          },
        },
      },
    },
    title: {
      width: '125px',
      height: '32px',
      '& > div,& > span': {
        verticalAlign: 'top',
      },
      '& img': {
        width: '125px',
        height: '32px',
        verticalAlign: 'top',
      },
    },
    drawer: {
      '& .MuiDrawer-paper': {
        backgroundColor: '#41474C',
        '&::-webkit-scrollbar': {
          width: '0',
        },
      },
    },
    leftNaviClose: {
      '&.MuiButtonBase-root': {
        backgroundColor: '#2E363A',
        padding: '20px 24px',
        lineHeight: 1,
        color: '#FDFDFD',
        fontSize: '14px',
        borderRadius: 0,
        justifyContent: 'start',
        '& .MuiButton-label': {
          justifyContent: 'start',
        },
        '& .icon': {
          marginRight: '8px',
          '& svg': {
            width: '20px',
            height: '20px',
            fill: '#C5C8CE',
          },
        },
      },
    },
    searchMenu: {
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
    },
    setting: {
      '&.MuiButtonBase-root': {
        [theme.breakpoints.down('md')]: {
          display: 'none !important',
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

type Props = {
  isVisibleSearch?: boolean;
  isVisibleBanner?: boolean;
};
const Header: React.FC<Props> = ({ isVisibleSearch = true, isVisibleBanner = true }) => {
  const classes = useStyles();
  const { isAuthenticated, isFetched } = useAuthStateContext();
  const router = useRouter();
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const liRef = useRef<HTMLLIElement>(null);

  const onOpenDrawer = useCallback(() => {
    setIsOpenDrawer(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setIsOpenDrawer(false);
  }, []);

  useEffect(() => {
    // Continue to keep in session to display dynamic links even if route changes
    const li = liRef.current;
    if (li?.innerHTML && sessionStorage.getItem('grpNoteDom')) li.innerHTML = sessionStorage.getItem('grpNoteDom');

    return () => {
      li?.innerHTML && sessionStorage.setItem('grpNoteDom', li.innerHTML);
    };
  }, [router.pathname]);

  return (
    <>
      <MNOBanner />
      {isVisibleBanner && (
        <div className={classes.headerBanner}>
          <Device.CustomView condition={Device.isBrowser && !Device.isTablet}>
            <Script strategy="afterInteractive" src="//jp.rakuten-static.com/1/js/lib/prm_selector_02.js" />
            <nav className={classes.headerNav}>
              <ul className="headerNav-List">
                <li id="grpNote" ref={liRef} />
                <li className="headerNav-listGroup">
                  <a className="headerNav-listGroup-link" href="//www.rakuten.co.jp/sitemap/">
                    楽天グループ
                  </a>
                  <ul className="headerNav-listGroup-menu">
                    <li>
                      <a href="//member.insight.rakuten.co.jp/?scid=wi_grp_gmx_shw_hepullbu_rsc" rel="nofollow">
                        楽天インサイト
                      </a>
                    </li>
                    <li>
                      <a href="//travel.rakuten.co.jp/?scid=wi_grp_gmx_shw_hepullbu_trv" rel="nofollow">
                        楽天トラベル
                      </a>
                    </li>
                    <li>
                      <a href="//www.rakuten-bank.co.jp/?scid=wi_grp_gmx_shw_hepullbu_bnk" rel="nofollow">
                        楽天銀行
                      </a>
                    </li>
                    <li>
                      <a href="//www.rakuten-edy.co.jp/?scid=wi_grp_gmx_shw_hepullbu_edy" rel="nofollow">
                        楽天Edy
                      </a>
                    </li>
                    <li>
                      <a href="//www.rakuten.co.jp/sitemap/">サービス一覧</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="//books.rakuten.co.jp/?scid=wi_grp_gmx_shw_hepullbu_boo" rel="nofollow">
                    楽天ブックス
                  </a>
                </li>
                <li>
                  <a href="https://www.infoseek.co.jp/?scid=wi_grp_gmx_shw_hetopbu_ifs" rel="nofollow">
                    インフォシーク
                  </a>
                </li>
                <li>
                  <a href="https://ad2.trafficgate.net/t/r/10464/1441/99636_99636/" rel="nofollow">
                    カード
                  </a>
                </li>
                <li>
                  <a href="//www.rakuten.co.jp/">楽天市場</a>
                </li>
              </ul>
            </nav>
          </Device.CustomView>
        </div>
      )}

      <SwipeableDrawer
        anchor="left"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        onOpen={onOpenDrawer}
        className={classes.drawer}
      >
        <Button className={classes.leftNaviClose} aria-label="close left side menu" onClick={onCloseDrawer}>
          <span className="icon">
            <Close />
          </span>
          閉じる
        </Button>
        <LeftNavi />
      </SwipeableDrawer>
      <div className={classes.header}>
        <div className="leftClm">
          <IconButton
            edge={false}
            aria-label="open left side menu"
            onClick={onOpenDrawer}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Link href={NEXT_PUBLIC_RTV_WEB_URL} passHref>
            <h1 className={classes.title}>
              <Image
                src={`${NEXT_PUBLIC_IM_URL}/common/img/globalNav/rtv-logo.svg`}
                alt="rakuten tv logo"
                width={125}
                height={32}
              />
            </h1>
          </Link>
        </div>

        {isVisibleSearch ? <SearchBox className="search" /> : null}

        <div className="rightClm">
          {isVisibleSearch && <SearchMenu className={classes.searchMenu} />}
          <PurchaseHistory />
          {isFetched && (
            <>
              {!isAuthenticated && <BeginnerGuide />}
              {!isAuthenticated && <Login />}
              {isAuthenticated && <Favorite />}
              {isAuthenticated && <Information />}
            </>
          )}
          <Setting className={classes.setting} />
        </div>
      </div>
    </>
  );
};

export default Header;
