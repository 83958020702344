import { useEffect, useState, memo } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    mnoBanner: {
      background: '#fff',
    },
  }),
);

const MemolizedScript = memo(
  (props: { id: string }) =>
    props.id && (
      <>
        {/* In order to be able to display the banner even during route changes, create.js needs to be reloaded. */}
        <Script strategy="lazyOnload" src={`//jp.rakuten-static.com/1/grp/banner/js/create.js?v=${props.id}`} />
      </>
    ),
);

const MNOBanner: React.FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const [uid, setUid] = useState<string>('');

  useEffect(() => {
    setUid(new Date().getTime().toString());
  }, [router.pathname]);

  return (
    <>
      <MemolizedScript id={uid} />
      <div className={classes.mnoBanner}>
        <div id="mkdiv_header_pitari" />
      </div>
    </>
  );
};

export default MNOBanner;
